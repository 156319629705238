import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const NewsCardStyled = styled.div`
  background: var(--backgroundProduct);
  border: 1px solid var(--border);
  color: var(--textColor);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  a {
    color: var(--borderColor);
    text-decoration: none;
  }
  a:hover {
    cursor: pointer;
  }
  .news-image {
    display: grid;
    align-items: center;
    justify-items: center;
    margin: 0;
  }
  .discipline,
  .news-image {
    padding: 1.5rem;
  }
  .news-title,
  .news-explore {
    text-transform: none;
    padding: 1.5rem 1.5rem 1.5rem 0;
    margin-left: 1.5rem;
    border-top: 1px solid var(--border);
  }

  .news-excerpt {
    padding: 0 1.5rem 1.5rem 0;
    margin-left: 1.5rem;
  }
  .discipline {
    border-top: 1px solid var(--blackMatte);
    display: block;
    text-transform: uppercase;
  }
`;

export default function NewsCard(props) {
  const truncateBy = 250;
  const desc = `${
    props.description
      ? props.description
      : "Dedicated to producing the world's most technically advanced and anatomically correct cycling shoes."
  }`;
  const truncatedDesc = desc.substring(0, truncateBy);
  return (
    <NewsCardStyled>
      <Link to={`/news/${props.slug}`}>
        <Img
          fluid={props.image}
          alt={props.title}
          className="news-image"
          imgStyle={{ objectFit: 'cover' }}
        />
      </Link>
      <span className="discipline">
        {props.category} - {props.date}
      </span>
      <h6 className="news-title">{props.title}</h6>
      <div className="news-excerpt">{truncatedDesc}</div>
      <div className="news-explore">
        <Link to={`/news/${props.slug}`}>Read the article</Link>
      </div>
    </NewsCardStyled>
  );
}
